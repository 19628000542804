<template>
    <div>
        <nav class="hidden md:flex mb-4 h-8" aria-label="Breadcrumb">
            <ol role="list" class="flex space-x-4 rounded-md bg-white px-6 shadow">
            <li class="flex">
                <div class="flex items-center">
                <router-link to="/hub" class="transition ease-in-out duration-300 text-gray-400 hover:text-gray-500">
                    <HomeIcon class="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                    <span class="sr-only">Home</span>
                </router-link>
                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>

                <router-link to="/dashboard/storage" class="transition ease-in-out duration-300 ml-4 text-xs font-medium text-gray-500 hover:text-gray-700" aria-current="page">Moduł magazyn</router-link>

                </div>
            </li>
            <li class="flex">
                <div class="flex items-center">
                <svg class="h-full w-6 flex-shrink-0 text-gray-200" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                </svg>
                <div class="ml-4 text-xs font-medium text-gray-400 cursor-default" aria-current="page">Lista zamówień</div>
                </div>
            </li>
            </ol>
        </nav>
        <router-link to="/dashboard/storage" class="block md:hidden transition ease-in-out duration-300 mb-2 text-sm font-medium text-blue-500 hover:text-blue-700" aria-current="page">&larr; Cofnij</router-link>

        <!-- SEARCHER -->
        <div class="min-w-0 flex-1 xl:col-span-6">
            <div :class="{'flex items-center justify-end': true}">
                <div :class="{'w-full flex': true, 'justify-end': true, 'flex-col space-y-4 lg:space-y-0 lg:flex-row': this.showStatusFilters}">
                    <!-- <Transition name="fade">
                    <div v-if="!this.showStatusFilters" class="w-2/3 sm:w-1/3 flex">
                        <label for="search" class="sr-only">Szukaj</label>
                        <div class="relative">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input :disabled="this.searcher.locked" v-model="searcher.phrase" id="search" name="search" :class="['block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:border-blue-500 focus:text-gray-900 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500', this.searcher.locked ? 'opacity-50' : '']" placeholder="Wyszukaj" type="search" />
                        </div>
                        <button :disabled="this.searcher.locked" type="button" @click.prevent="search" :class="['transition ease-in-out duration-300 ml-2 inline-flex items-center rounded border border-transparent bg-blue-100 px-2.5 py-1.5 text-xs font-medium text-blue-700', this.searcher.locked || this.searcher.phrase.length === 0 ? 'opacity-50 cursor-default' : 'hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']">Szukaj</button>
                    </div>
                    </Transition> -->
                    <Transition name="fade">
                    <div v-if="showStatusFilters === true" class="mr-4 flex flex-col lg:flex-row w-full lg:items-center lg:space-x-3 space-y-4 lg:space-y-0 select-none">
                        <div class="flex flex-col lg:flex-row justify-center mt-2 lg:mt-0 lg:items-center lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="flex items-center">
                                <div :class="{'relative w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600': true, 'opacity-50': pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null}">
                                    <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900">Data utworzenia</label>
                                    <input :disabled="pickedDateOfDelivery !== null || pickedDateOfDeliveryUnix !== null" type="date" @change="pickDateOfCreate($event)" v-model="pickedDateOfCreate" ref="pickedCreatedDate"  name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" />
                                </div>
                                <Transition name="fade2">
                                <div v-if="pickedDateOfCreate !== null || pickedDateOfCreateUnix !== null" class="ml-2 mr-2">
                                    <span @click="resetDateOfCreate" class="transition ease-in-out duration-300 text-sm text-red-500 hover:text-red-700 cursor-pointer">&#10005;</span>
                                </div>
                                </Transition>
                            </div>
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Sortowanie</label>
                                <select @change="changeSort()" v-model="sortName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="creationDesc">Utworzono: Od najnowszych</option>
                                    <option value="creationAsc">Utworzono: Od najstarszych</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex flex-col lg:flex-row lg:space-x-3 space-y-4 lg:space-y-0">
                            <div class="relative rounded-md focus-within:border-indigo-600 focus-within:ring-1 focus-within:ring-indigo-600">
                                <label for="name" class="absolute -top-2 left-2 -mt-px inline-block bg-gradient-to-t from-white to-gray-100 px-1 text-xs font-medium text-gray-900 rounded-t-md">Producent</label>
                                <select @change="changeSort()" v-model="manuName" class="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                    <option value="empty">Brak</option>
                                    <option v-for="(manu, index) in manufacturers" :key="index" :value="manu.id">{{manu.name}}</option>
                                </select>
                            </div>
                            <button type="button" @click="this.pickStatus()" class="transition ease-in-out duration-300 inline-flex items-center justify-center rounded border border-transparent bg-blue-100 px-2.5 py-2 lg:py-1.5 text-xs font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Zastosuj</button>
                        </div>
                    </div>
                    </Transition>
                    <div v-if="searcher.found.length === 0" :class="{'flex justify-end items-end transition-all duration-300': true, 'min-w-filters': showStatusFilters}">
                        <div @click="showFilters()" class="mt-1 cursor-pointer flex justify-center items-center flex-col select-none">
                            <FilterIcon class="h-4 w-4 text-blue-500 flex-shrink-0" aria-hidden="true" />
                            <span class="text-xs mt-1 font-medium text-blue-700">{{showStatusFilters ? 'Resetuj filtry' : 'Filtry'}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="searcher.found.length > 0">
                <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                    Wynik wyszukiwania dla frazy: "{{accurateSearch ? searcher.phrase :  searcher.lastCall.phrase}}" (Znaleziono: {{searcher.found.length}})
                    <button @click.prevent="resetSearch"  type="button" class="transition ease-in-out duration-300 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-blue-400 hover:bg-blue-200 hover:text-blue-500 focus:outline-none focus:bg-blue-500 focus:text-white">
                        <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                        </svg>
                    </button>
                </span>          
            </div>
        </div>

        <div class="mt-4 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 lg:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Data</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Zamówienie</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Producent</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Ilość</th>
                                <th scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <!-- <div class="flex justify-end">
                                        <button @click.prevent="modalAccept.open = true" :disabled="this.markedOrders.length === 0" type="button" :class="[this.markedOrders.length > 0 ? '' : 'opacity-50 pointer-events-none', 'transition-all duration-300 inline-flex items-center gap-x-0 sm:gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600']">
                                            <CheckCircleIcon class="ml-0 sm:-ml-0.5 h-5 w-5" aria-hidden="true" />
                                            <span class="hidden sm:inline-flex">Akceptuj</span>
                                        </button>
                                    </div> -->
                                </th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white" v-for="(order, index) in searcher.found.length > 0 ? searcher.found : orders" :key="order.id">
                                <tr>
                                    <td class="whitespace-nowrap py-4 pr-3 pl-4 text-xs sm:pl-6">
                                        <div class="flex items-center">
                                            <span class="font-semibold">{{moment.unix(order.meta.created.unix).format('LLL')}}</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex items-center">
                                            <span class="bg-blue-100 text-blue-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer">{{order.id}}</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex items-center">
                                          <span class="font-medium">{{createManufacturerName(order.manufacturerId)}}</span>
                                        </div>
                                    </td>
                                    <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <span @click.prevent="openAdditionalInformation(index)" v-tooltip="`Ilość odebranych: ${order.amounts.received} → Ilość zamówionych: ${order.amounts.total}`" :class="[order.amounts.received === order.amounts.total ? 'text-green-700 bg-green-50 hover:bg-green-300 ring-green-700 ring-opacity-10' : 'text-indigo-700 ring-indigo-700 ring-opacity-30 bg-indigo-50 hover:bg-indigo-200', 'transition-all duration-300 cursor-pointer inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ']">
                                            <template v-if="order.amounts.received !== order.amounts.total">
                                                <span :class="[order.amounts.received > 0 ? 'text-indigo-700' : 'text-red-500',]">{{order.amounts.received}}</span>/
                                                <span class="text-indigo-700">{{order.amounts.total}}</span>
                                            </template>
                                            <template v-if="order.amounts.received === order.amounts.total">
                                                <span >{{order.amounts.received}}/{{order.amounts.total}}</span>
                                            </template>
                                        </span>
                                    </td>
                                     <td class="whitespace-nowrap py-4 pr-3 text-xs">
                                        <div class="flex items-center">
                                            <div v-if="order.sendingEmailLoader" class="bg-blue-100 text-blue-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">
                                                <span class="mr-2">Wysyłanie</span>
                                                <div class="spinner"></div>
                                            </div>
                                            <div v-else>
                                                <span v-if="order.emailSent" class="bg-green-100 text-green-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">Wysłano</span>
                                            <span v-else class="bg-yellow-100 text-yellow-800 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium">Nie wysłano</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="relative flex justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                        <div class="flex items-center" @click.prevent="openAdditionalInformation(index)">
                                            <ChevronDownIcon :class="[this.toggleInformation[index].open ? 'transform rotate-180' : '', 'h-5 w-5 text-gray-400 hover:text-gray-600 transition-all duration-500 cursor-pointer']" />
                                        </div>
                                    </td>
                                </tr>
                                <Transition name="fade">
                                <td colspan="12" v-show="this.toggleInformation[index].open" class="bg-gray-100 border-t border-gray-200">
                                    <div>
                                        <div class="flow-root overflow-hidden">
                                            <div class="mx-auto max-w-7xl">
                                                <table class="w-full text-left">
                                                <thead>
                                                    <tr>
                                                    <th scope="col" class="w-1/2 relative py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 lg:pl-6">
                                                        Nazwa produktu
                                                        <div class="absolute inset-y-0 right-full -z-10 w-screen border-b border-b-gray-200" />
                                                        <div class="absolute inset-y-0 left-0 -z-10 w-screen border-b border-b-gray-200" />
                                                    </th>
                                                    <th scope="col" class="border-l-2 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">Zamówienie</th>
                                                    <!-- <th scope="col" class="border-l-2 relative py-3.5 pl-3 text-right flex justify-center">
                                                        <div v-show="order.amounts.received !== order.amounts.total" class="h-6" v-tooltip="`Zaznacz wszystkie pozycje.`">
                                                            <input @click="markEverything(order, $event)" :ref="`checkAll-${order.id}`" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                        </div>
                                                    </th> -->
                                                    <th scope="col" class="border-l-2 relative py-4 text-right text-sm font-medium flex flex-row h-12">
                                                        <span class="sr-only">Status zamówienia</span>
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(prod, index) in order.variants" :key="prod" :class="[index % 2 === 0 ? 'bg-gray-50' : 'bg-gray-100']">
                                                        <td class="relative py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 pl-6">
                                                            <span class="bg-indigo-100 text-indigo-800 inline-flex items-center rounded-full px-2.5 py-0.5 font-medium cursor-pointer">
                                                                {{prod.item.name}}
                                                            </span>
                                                            <div class="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                            <div class="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                        </td>
                                                        <td class="border-l-2 hidden px-3 py-4 text-sm sm:table-cell">
                                                            <router-link :to="prod.origin === 'directly' ? '' : `/dashboard/orders/add?edit=${prod.origin}`" :target="prod.origin === 'directly' ? '' : '_blank'" :class="[prod.origin === 'directly' ? 'cursor-default bg-red-100 text-red-800' : 'cursor-pointer bg-purple-100 text-purple-800', ' inline-flex items-center rounded-full px-2.5 py-0.5 transition-all duration-300 gap-1']">
                                                                {{prod.origin === 'directly' ? 'Bezpośrednio' : prod.origin}}
                                                                <ExternalLinkIcon v-if="prod.origin !== 'directly'" class="h-5 w-5 cursor-pointer" />
                                                            </router-link>
                                                        </td>
                                                        <!-- <td class="border-l-2 relative py-4 pl-3 text-right text-sm font-medium flex justify-center">
                                                            <div class="h-6" v-tooltip="`${prod.blocked ? 'Pozycja już została przyjęta.' : 'Zaznacz pozycje.'}`">
                                                                <input :disabled="prod.blocked" @click="receiveOrder(prod, $event, order.id),checkMarked(order)" v-model="prod.received" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                            </div>
                                                        </td> -->
                                                        <td class="border-l-2 relative py-4 text-right text-sm font-medium flex justify-center">
                                                            <span :class="[prod.blocked ? 'bg-green-50 ring-green-600 text-green-700' : 'bg-red-50 ring-red-600 text-red-700', 'w-48 flex justify-center items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-opacity-20']">{{prod.blocked ? 'Pozycja została przyjęta.' : 'Pozycja nie została przyjęta.'}}</span>
                                                            <!-- <div class="h-6" v-tooltip="`${prod.blocked ? 'Pozycja już została przyjęta.' : 'Zaznacz pozycje.'}`">
                                                                <input :disabled="prod.blocked" @click="receiveOrder(prod, $event, order.id),checkMarked(order)" v-model="prod.received" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                            </div> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                </Transition>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="orders.length === 0"></EmptyState>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreOrders" class="transition ease-in-out duration-300 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Załaduj więcej..</button>
        </div>
    </div>
    <askForAccept @accept="acceptProducts()" @closeModal="closeModal()" :closeModalAfterAccept="false" :buttonBlocked="modalAccept.buttonBlocked" :modalData="modalData" :buttonColor="'indigo'" :iconColor="'indigo'" v-if="modalAccept.open" />
</template>

<script>
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import { SearchIcon, PlusSmIcon, FilterIcon, ChevronDownIcon, ExternalLinkIcon, CheckCircleIcon } from '@heroicons/vue/outline';
import { HomeIcon } from '@heroicons/vue/solid';
import { Switch } from '@headlessui/vue'
import EmptyState from '@/components/EmptyState.vue';
import askForAccept from '../components/AlertModal.vue';
export default {
  name: "StorageManufacturerList",
  components: {
    askForAccept, CheckCircleIcon, ExternalLinkIcon, SearchIcon, HomeIcon, PlusSmIcon, EmptyState, Switch, FilterIcon, ChevronDownIcon
  },
  data() {
    return {
        sendingEmailLoader: false,
        modalData: {
            headerText: "Akceptowanie pozycji",
            bodyText: "Czy na pewno chcesz akceptować wybrane pozycje?",
            acceptButtonText: "Akceptuj"
        },
        modalAccept: {
            open: false,
            buttonBlocked: false
        },
        hideOptions: false,
        accurateSearch: false,
        query: null,
            sortName: "creationDesc",
        sortType: {
            order: "desc",
            type: "meta.created.unix"
        },
        pickedDateOfCreate: null,
        pickedDateOfCreateUnix: null,
        pickedDateOfDelivery: null,
        pickedDateOfDeliveryUnix: null,
        showStatusFilters: false,
        countriesData: [],
        manufacturers: [],
        manuName: 'empty',
        loading: false,
        searcher: {
            found: [],
            locked: false,
            phrase: "",
            lastCall: null
        },
        orders: [],
        queryLimit: 10,
        dbListener: null,
        queryLimitReached: false,
        toggleInformation: {},
        markedOrders: [],
        addedAll:null,
    }
  },
  async created() {
    moment.locale('pl')
    this.moment = moment;
    if(!this.$store.state.userData.permissions.admin && !this.$store.state.userData.permissions.storage)
    {
      this.$router.push("/hub")
    }
    await this.getManufacturers()
    await this.getAllOrders()
  },
  methods: {
    closeModal() {
        this.modalAccept.open = false
    },
    checkMarked(order)
    {
        this.addedAll = true
        for(let i = 0; i<order.variants.length; i++)
        {
            if(!order.variants[i].added || !order.variants[i].received)
            {
            this.addedAll = false
                break;
            }
        }
        if(this.addedAll === true){
            this.$refs[`checkAll-${order.id}`][0].checked = true
        }
        else{
        this.$refs[`checkAll-${order.id}`][0].checked = false

        }
        
    },
    async acceptProducts()
    {
        try
        {
            this.modalAccept.buttonBlocked = true
            const res = await axios.post(`${this.$store.state.apiLink}/storage/order/receive`, {
                receivedVariants: this.markedOrders
            })
            if(!res.data.success)
            {
                this.markedOrders = [];
                this.modalAccept.open = false;
                this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona niepowodzeniem!",
                subheader: `${res.data.log}`,
                success: false
                });
            }
            else
            {
                this.markedOrders = [];
                this.modalAccept.open = false;
                this.$store.commit('setNotification',{
                show: true,
                head: "Akcja zakończona sukcesem!",
                subheader: "Pozycje zostały przyjęte.",
                success: true
                });
            }
            this.modalAccept.buttonBlocked = false;
        }
        catch (error)
        {
            this.markedOrders = [];
            this.modalAccept.open = false;
            this.$store.commit('setNotification',{
            show: true,
            head: "Akcja zakończona niepowodzeniem!",
            subheader: `${res.data.log}`,
            success: false
            });
            this.modalAccept.buttonBlocked = false
            console.log(error);
        }
    },
    markEverything(order, event)
    {
        if(event.target.checked)
        {
            for(let i=0; i<order.variants.length; i++)
            {
                if(!order.variants[i].blocked && !order.variants[i].added)
                {
                    order.variants[i].added = true;
                    order.variants[i].received = true;
                    this.markedOrders.push({...order.variants[i]})
                }
            }
        }
        else
        {
            for(let i=0; i<order.variants.length; i++)
            {
                for(let y=0; y<this.markedOrders.length; y++)
                {
                    if(this.markedOrders[y].id === order.variants[i].id)
                    {
                        if(this.markedOrders[y].origin === order.variants[i].origin)
                        {
                            if(!order.variants[i].blocked)
                            {
                                order.variants[i].added = false;
                                order.variants[i].received = false;
                                this.markedOrders.splice(y, 1)
                            }
                        }
                    }
                }
            }
        }
    },
    receiveOrder(order, event)
    {
        if(event.target.checked)
        {
            order.added = true;
            order.received = true;
            this.markedOrders.push({...order})
        }
        else
        {
            order.added = false;
            order.received = false;
            for(let i=0; i<this.markedOrders.length; i++)
            {
                if(order.id === this.markedOrders[i].id && order.productOrderId === this.markedOrders[i].productOrderId)
                {
                    this.markedOrders.splice(i, 1)
                    break;
                }
            }
        }
    },
    openAdditionalInformation(indx)
    {
        if(this.toggleInformation[indx].open)
        {
            this.toggleInformation[indx].open = false
        }
        else
        {
            for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
                this.toggleInformation[i].open = false
            }
            this.toggleInformation[indx].open = true
        }
    },
    async getManufacturers()
    {
      const res = await db.collection('Manufacturers').get()
      res.docs.forEach(doc => {
        let current = doc.data()
        this.manufacturers.push(current)
      });
    },
    createManufacturerName(manu)
    {
      let translatedNameOfManufacturer = '';
      for(let i=0;i<this.manufacturers.length; i++)
      {
        if(manu === this.manufacturers[i].id)
        {
          translatedNameOfManufacturer = this.manufacturers[i].name;
        }
      }
      return translatedNameOfManufacturer
    },
    pickDateOfDelivery(event){
        this.hideOptions = true;
        if(this.hideOptions)
        {
            this.sortName = 'suggestedDeliveryAsc';
        }
        let mDate = moment(event.target.value)
        if(!mDate.isValid())
        {
            this.$store.commit('setNotification',{
                show: true,
                head: "Popraw datę!",
                subheader: `Wykryto, że data jest niepoprawna.`,
                success: false
            });
            this.pickedDateOfDelivery = null;
            this.pickedDateOfDeliveryUnix = null
        }
        else
        {
            this.pickedDateOfDeliveryUnix = mDate.unix()
        }
    },
    pickDateOfCreate(event){
        let mDate = moment(event.target.value)
        if(!mDate.isValid())
        {
            this.$store.commit('setNotification',{
                show: true,
                head: "Popraw datę!",
                subheader: `Wykryto, że data jest niepoprawna.`,
                success: false
            });
            this.pickedDateOfCreate = null;
            this.pickedDateOfCreateUnix = null
        }
        else
        {
            this.pickedDateOfCreateUnix = mDate.unix()
        }
    },
    resetDateOfCreate(){
        this.hideOptions = false;
        this.pickedDateOfCreate = null;
        this.pickedDateOfCreateUnix = null
    },
    resetDateOfDelivery(){
        this.hideOptions = false;
        this.pickedDateOfDelivery = null;
        this.pickedDateOfDeliveryUnix = null
    },
    changeSort()
    {
        if(this.sortName === "creationAsc"){
            this.sortType = {
                order: "asc",
                type: "meta.created.unix"
            }
        }
        if(this.sortName === "creationDesc"){
            this.sortType = {
                order: "desc",
                type: "meta.created.unix"
            }
        }
    },
    async getAllOrders()
    {
        this.query = null;
        if(this.dbListener !== null){
            this.dbListener()
            this.dbListener = null
        }
        this.query = db.collection("ProductOrders").where("allReceived", "==", true)

      
        if(this.manuName !== 'empty')
        {
            this.query = this.query.where("manufacturerId", "==", this.manuName)
        }

        if(this.pickedDateOfCreateUnix !== null){
            this.pickedDateOfCreateUnix = parseInt(this.pickedDateOfCreateUnix)
            let startOfDay = this.pickedDateOfCreateUnix
            let endOfDay = this.pickedDateOfCreateUnix + 86399
            this.query = this.query.where('meta.created.unix', ">=", startOfDay).where('meta.created.unix', "<=", endOfDay)
        }

        this.query = this.query.orderBy(`${this.sortType.type}`, `${this.sortType.order}`)

        this.dbListener = this.query.limit(this.queryLimit)
        .onSnapshot((querySnapshot) => {
            this.orders = [];
            if(querySnapshot.docs.length<this.queryLimit)
            {
                this.queryLimitReached = true;
            }
            else
            {
                this.queryLimitReached = false;
            }
            querySnapshot.forEach(doc => {
                let current = doc.data()
                for(let i=0; i<current.variants.length; i++)
                {
                    current.variants[i].productOrderId = current.id;
                    if(current.variants[i].received)
                    {
                        current.variants[i].blocked = true;
                        current.variants[i].added = true;
                    }
                    else
                    {
                        current.variants[i].blocked = false;
                        current.variants[i].added = false;
                    }
                }
                this.orders.push(current);
            });
            for(let t=0; t<this.orders.length; t++)
            {
                this.toggleInformation[t] = {
                    open: false
                }
            }
        });
    },
    showFilters()
    {
      this.showStatusFilters = !this.showStatusFilters
      if(!this.showStatusFilters){
        this.manuName = 'empty';
        this.pickedDateOfCreate = null;
        this.pickedDateOfCreateUnix = null
        this.pickedDateOfDelivery = null;
        this.pickedDateOfDeliveryUnix = null

        this.sortName = "creationDesc",
        this.sortType = {
            order: "desc",
            type: "meta.created.unix"
        }
        this.pickStatus()
      }
    },
    pickStatus()
    {
      this.dbListener()
      this.getAllOrders()
    },
    resetSearch()
    {
      this.searcher.found = [];
      this.searcher.lastCall = null;
      this.searcher.phrase = "";
      this.searcher.locked = false
    },
    async search()
    {
      if(this.accurateSearch)
      {
          if(this.searcher.phrase.length > 0)
          {
              this.searcher.locked = true;
              const res = await db.collection("Orders").where("origin.meta.external.ids.externalOrderId", "==", this.searcher.phrase).get()
              if(!res.empty)
              {
                  let allFound = []
                  res.docs.forEach((found) => {
                  let current = found.data();
                  current.countryFlagURL = "";
                      if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                      {
                          current.delivery.country.code = current.invoice.countryCode;
                      }
                      for(let y=0; y< this.countriesData.length; y++)
                      {
                          if(this.countriesData[y].code === current.delivery.country.code)
                          {
                              current.countryFlagURL = this.countriesData[y].flagURL;
                          }
                      }
                      allFound.push(current);
                  });
                  if(res.docs.length > 0)
                  {
                      this.searcher.found = allFound;
                      this.dbListener();
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Wyszukiwanie zakończone!",
                          subheader: `Znaleziono ${res.docs.length} zamówień.`,
                          success: true
                      });

                  }else{
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Nie znaleziono wyników!",
                          subheader: `Spróbuj wyszukać inną frazę.`,
                          success: false
                      });
                  }

              }
              else
              {
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "Wyszukiwanie zakończone niepowodzeniem!",
                      subheader: `Coś poszło nie tak...`,
                      success: false
                  });
              }
              setTimeout(()=>
              {
                  this.searcher.locked = false;
              }, 3000)
          }
          else
          {
              return;
          }
      }
      else
      {
          if(this.searcher.phrase.length > 0)
          {
              this.searcher.locked = true;
              const res = await axios.post(`${this.$store.state.apiLink}/search`, {
                  index: "jan-art-orders",
                  collection: "Orders",
                  phrase: this.searcher.phrase,
                  searchFields: ['client.name', 'client.company', 'delivery.name', 'delivery.company', 'id', 'invoice.fullname', 'delivery.postCode', 'externalId'],
                  archive: false
              })
              if(res.data.success)
              {
                  let allFound = []
                  res.data.found.forEach((found) => {
                  let current = found
                  current.countryFlagURL = "";
                      if(current.delivery.country.code.length === 0 && current.invoice.countryCode.length > 0)
                      {
                          current.delivery.country.code = current.invoice.countryCode;
                      }
                      for(let y=0; y< this.countriesData.length; y++)
                      {
                          if(this.countriesData[y].code === current.delivery.country.code)
                          {
                              current.countryFlagURL = this.countriesData[y].flagURL;
                          }
                      }
                      allFound.push(current);
                  });
                  this.searcher.found = allFound;
                  if(res.data.found.length > 0)
                  {
                      this.searcher.lastCall = res.data.data;
                      this.dbListener();
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Wyszukiwanie zakończone!",
                          subheader: `Znaleziono ${res.data.found.length} zamówień.`,
                          success: true
                      });

                  }else{
                      this.$store.commit('setNotification',{
                          show: true,
                          head: "Nie znaleziono wyników!",
                          subheader: `Spróbuj wyszukać inną frazę.`,
                          success: false
                      });
                  }

              }
              else
              {
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "Wyszukiwanie zakończone niepowodzeniem!",
                      subheader: `Coś poszło nie tak...`,
                      success: false
                  });
              }
              setTimeout(()=>
              {
                  this.searcher.locked = false;
              }, 3000)
          }
          else
          {
              return;
          }
      }
    },
    loadMoreOrders()
    {
        this.queryLimit += 10;
        this.dbListener();
        this.getAllOrders()      
    },
  },
  beforeUnmount() {
    if(this.dbListener !== null){
        this.dbListener();
    }
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active{
  transition: opacity 1s ease;
}
.fade-leave-active{
  transition: opacity 0s;
  display: none;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade2-enter-active,
.fade2-leave-active {
  transition: opacity 0.5s ease;
}

.fade2-enter-from,
.fade2-leave-to {
  opacity: 0;
}

.spinner {
   width: 1rem;
   height: 1rem;
   border-radius: 50%;
   background: radial-gradient(farthest-side,#9ca3af 80%,#0000) top/9px 9px no-repeat,
          conic-gradient(#0000 30%,#9ca3af);
   -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 2px),#000 0);
   animation: spinner-c7wet2 1s infinite linear;
}

@keyframes spinner-c7wet2 {
   100% {
      transform: rotate(1turn);
   }
}

</style>